@import "scss/base.scss";


.loginPage {
  text-align: center;
  margin: auto;
  width: $full;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $light-grey;

  .loginPageContent {
    max-width: 423px;
  }

  div.vestaLogo {
    height: $logo-height;
    width: $logo-height;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $margin-large;
  }

  .header {
    margin-top: $margin-tiny;
    margin-bottom: $margin-large;
  }

  .left {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .right {
    padding-left: $padding-largest * 3;
    width: $one-third;
  }

  &.showRolePermissions {
    flex-direction: row;
  }
}
