@import "scss/base.scss";

.assignUsersTableContainer {
  margin-top: $margin-small;
  
  .assignUsersTable {
    border: solid $border-size $border-color;
    max-height: $table-max-height-small;
    overflow-y: scroll;
    margin-top: $margin-tiny;
  }

  .usersSortSearch {
    display: flex;
    justify-content: flex-end;

    .searchInput {
      margin-left: $margin-small;
    }
  }

  .assignUsersTableHeaders {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $margin-tiny;
  
    .smallText {
      color: $vesta-blue;
    }
  
    .assignedNone {
      visibility: hidden;
    }
  }

  .noDeptMessage {
    margin: $margin-small 0;
    display: flex;
    justify-content: center;
  }
  
  .userRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $padding-tiniest $padding-tiny;
    border-bottom: none;
    border-bottom: solid $border-size $border-color;
  
    &:last-child {
      border-bottom: none;
    }

    .userNameAndRole {
      display: flex;
      flex: 1;
      align-items: center;
    }

    .userNote {
      color: $vesta-blue;
      margin-left: $margin-tiny;
      font-style: italic;
    }

    .roleName {
      color: $off-grey;
    }

    &.noViewReportDetailsPermission {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
