@import "scss/base.scss";

div.defaultIcon {
    height: $font-size-large;
    width: $font-size-large;
}

div.inlineIcon {
    height: $font-size-large;
    width: $font-size-large;
    display: inline-block; 
}