@import "scss/base.scss";
@import "scss/icons.scss";

.loginForm {
  div.arrowIcon {
    height: $font-size-large;
    width: $font-size-large;
  }

  .inputField {
    margin-bottom: $margin-large;

    .labels {
      display: inline-block;
      margin-right: $margin-tiny;
    }
  }

  .otherButtons {
    display: flex;

    button {
      margin: 0;
      flex: 1;

      &.contactButton {
        text-align: right;
      }
    }
  }
}
