@import "scss/base.scss";

.reportTimeStepOuter {
  padding: $padding-small 0px $padding-tiny $padding-largest;
  margin: 0 $margin-large;
  position: relative;

  &:after {
    padding: 0;
    margin: 0;
    content: "";
    width: 6px;
    height: 100%;
    background-color: $border-color;
    position: absolute;
    left: 3px;
    top: -1px;
  }

  &:first-child {
    &:after {
      top: calc(50% + 25px);
    }
  }

  &:last-child {
    &:after {
      bottom: calc(50% - 25px);
      top: auto;
    }
  }
}

.adminNote {
  .reportTimeStep {
    padding: $padding-tiny $padding-small;

    &::before {
      content: "";
      width: 7px;
      height: $full;
      background-color: $vesta-blue;
      position: absolute;
      top: 0;
      left: 0px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}

.reportTimeStep {
  @include cardStyle;
  padding: $padding-tiny $padding-tiny;
  position: relative;

  &:after {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: white;
    position: absolute;
    z-index: 99;
    left: -36px;
    top: $half;
    background: #FFFFFF;
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.23), 0 3px 6px 0 rgba(0,0,0,0.16);
  }
}

