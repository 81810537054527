@import "scss/base.scss";
@import "components/Modals/ReportStatusesModal/reportStatuses.scss";

$reportPropertyWidth: 80%/5;
$viewEditAssignWidth: 5%;

.reportsTable {
  background: white;
  padding: 0 $padding-large;
  position: relative;
  z-index: 2;

  .attMessageContainer {
    margin: $margin-large;
    display: flex;
    justify-content: center;

    &.noPermissions {
      margin: $margin-tiniest;
      justify-content: flex-start;
    }
  }

  .reportsFilterSortSearch {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > div:last-of-type {
        margin-left: $margin-tiny;
      }

      .attMessageContainer {
        margin: auto;
        margin-left: 0;
      }
  }

  .reportsTableContent {
    width: $full;
    padding-bottom: $padding-large;
    margin-top: $margin-large;
  }

  .reportRows {
    max-height: $table-max-height-large;
    overflow-y: scroll;
    border: solid 1px $border-color;
    margin-right: -$scrollbar-width;
  }

  &.isRecentReports {
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.23), 0 3px 6px 0 rgba(0,0,0,0.16);
    padding-top: $general-page-top-margin;
    
    .reportsTableContent {
      min-width: 512px;
    }
  }

  .labels {
    display: flex;
    align-items: center;
    padding-left: $padding-small;

    p, span {
      font-size: $font-size-microText;
    }

    .reportLabel {
      width: $reportPropertyWidth;
    }

    .assignedEntitiesLabel {
      width: $reportPropertyWidth;
    }

    .statusLabel {
      display: flex;
      width: $reportPropertyWidth;
      text-align: center;
      padding-left: $padding-small;

      .questionIcon {
        width: $icon-size;
        height: $icon-size;
        margin: 0;
        margin-left: $margin-tiny;
      }
    }

    .viewOrAssignLabel {
      width: $viewEditAssignWidth;
      text-align: center;
    }
  }

  .viewMoreInfoButton {
    width: $icon-size * 1.2;
    min-width: $icon-size * 1.2;
    height: $icon-size * 1.2;
    margin: 0;
    margin-right: $margin-tiny;
  }

  .consentInfoDropdown {
    background-color: $vesta-dark-pink;
    height: 0;
    overflow: hidden;

    .consentInfoDropdownHeader {
      padding-bottom: $padding-tiny;
      margin-bottom: $margin-tiny;
      border-bottom: solid 1px $border-color;
    }

    .consentInfoAttentionHeader {
      padding-bottom: $padding-tiny;
      margin: $margin-tiny 0;
      margin-top: $margin-small;
      border-bottom: solid 1px $border-color;

      .icon {
        width: $icon-size;
        height: $icon-size;
        filter: brightness(0) invert(1);
        margin-bottom: $margin-tiniest;
        margin-right: $margin-tiny;
      }
    }

    .consetFields {
      display: flex;
  
      p {
        flex: 1;
      }
  
      .consentAnswer {
        margin-left: $margin-tiny;
        font-family: 'FuturaHeavy';
      }
    }

    * {
      color: white;
    }

    &.active {
      height: auto;
      padding: $padding-tiny;
    }
  }
  
  .reportInfoDropdown {
    background-color: $vesta-blue;
    height: 0;
    overflow: hidden;

    .reportInfoDropdownContent {
        display: flex;

        .reportInfoContainer {
          flex: 1;

          p {
            margin: $margin-tiniest 0;
          }
        }
    }

    .reportInfoContainerHeader {
      margin-bottom: $margin-tiny;
    }

    .departmentInfoContainer {
      margin-bottom: $margin-tiny;
    }

    .reportInfoDropdownHeader {
      padding-bottom: $padding-tiny;
      margin-bottom: $margin-tiny;
      border-bottom: solid 1px $border-color;
    }

    * {
      color: white;
    }

    &.active {
      height: auto;
      padding: $padding-tiny;
    }
  }

  .assignedEntities {
    display: flex;
    align-items: center;
  }

  .report {
    display: flex;
    align-items: center;
    flex: 1;
    min-height: $table-row-min-height;
    border-bottom: solid $border-size $border-color;
    
    &:last-child {
      border-bottom: none;
    }

    .reportKeyAndDate, .assignedEntities {
      width: $reportPropertyWidth;
      padding-left: $padding-tiny;
    }

    .reportStatus {
      display: flex;
      align-items: center;
      width: $reportPropertyWidth;
    }

    .reportStatus::before {
      content: "•";
      font-size: $font-size * 2;
    }

    .iconColumn {
      width: $viewEditAssignWidth;
      display: flex;

      .externalLinkIcon, .personIcon {
        width: $icon-size-large;
        height: $icon-size-large;
      }

      .icon {
        position: relative;

        &.noPermission {
          opacity: 0.3;
          pointer-events: none;
          &::after {
            content: '';
            border-radius: 20px;
            width: 2px;
            height: 150%;
            display: block;
            margin: auto;
            transform: rotate(45deg);
            top: 0;
            bottom: 0;
            left: -5px;
            right: 0;
            position: absolute;
            background-color: $medium-grey;
          }
        }

      }

    }

  }

}
