@import "scss/base.scss";

.assignDepartmentsTableContainer {
  margin-top: $margin-tiny;

  .assignDepartmentsTable {
    border: solid $border-size $border-color;
    max-height: $table-max-height-small;
    overflow-y: scroll;
    margin-top: $margin-tiny;
  }

  .departmentsSortSearch {
    display: flex;
    justify-content: flex-end;

    .searchInput {
      margin-left: $margin-small;
    }
  }

  .assignDepartmentsTableHeaders {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .smallText {
      color: $vesta-blue;
    }
  
    .assignedNone {
      visibility: hidden;
    }
  }

  .noDeptMessage {
    margin: $margin-small 0;
    display: flex;
    justify-content: center;
  }
  
  .departmentRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $padding-tiniest $padding-tiny;
    border-bottom: solid $border-size $border-color;

    &:last-child {
      border-bottom: none;
    }

    .roleName {
      color: $off-grey;
    }
  }
}
