@import "scss/base.scss";

.loadingContainer {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.loading, .loading:before, .loading:after {
	border-radius: 50%;
	background-color: $white;
	width: $icon-size-tiny;
	height: $icon-size-tiny;
	transform-origin: center center;
	display: block;
}
.loading {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
	background-color: rgba($white,1);
	opacity: 1;
	-webkit-animation: loadingAlpha 1s infinite linear;
	animation: loadingAlpha 1s infinite linear;
}
.loading:before, .loading:after {
	content: '';
  opacity: 0.25;
  position: absolute;
}
.loading:before {
	left: 30px;
	top: 0px;
	-webkit-animation: loadingAlphaBefore 1s infinite linear;
	animation: loadingAlphaBefore 1s infinite linear;
}
.loading:after {
	left: -30px;
	top: 0px;
	-webkit-animation: lodingAlphaAfter 1s infinite linear;
	animation: lodingAlphaAfter 1s infinite linear;
}
@-webkit-keyframes loadingAlpha {
	0% { opacity: 1; }
	33% { opacity: 0.25; }
	66% { opacity: 0.25; }
	100% { opacity: 1; }
}
@keyframes loadingAlpha {
	0% { opacity: 1; }
	33% {  opacity: 0.25; }
	66% { opacity: 0.25; }
	100% {  opacity: 1; }
}
@-webkit-keyframes loadingAlphaBefore {
	0% { opacity: 0.25; }
	33% { opacity: 1; }
	66% { opacity: 0.25; }
}
@keyframes loadingAlphaBefore {
	0% { opacity: 0.25; }
	33% { opacity: 1; }
	66% { opacity: 0.25; }
}
@-webkit-keyframes lodingAlphaAfter {
	33% { opacity: 0.25; }
	66% { opacity: 1; }
	100% { opacity: 0.25; }
}
@keyframes lodingAlphaAfter {
	33% { opacity: 0.25; }
	66% { opacity: 1; }
	100% { opacity: 0.25; }
}

.button {
  appearance: none;
  border: none;
  box-shadow: none;
  width: fit-content;
  outline: none;
  text-align: center;
  margin: auto;
  min-height: $button-size-small;
  height: $button-size-small;
  line-height: $button-size-small;
  padding: 0 $padding-small;
  border-radius: $radius-large;
  cursor: pointer;
  width: $full;
  position: relative;
  height: $button-height;
  
  a {
    font-size: $font-size-small;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.skinnyPrimary {
    background-color: $vesta-pink;
    border-color: $vesta-pink;
    color: $white;
    height: $button-height-small;
    &:hover {
      background-color: rgba($vesta-pink, 0.75);
      border-color: rgba($vesta-pink, 0.75);
    }
  }

  &.skinnySecondary {
    background-color: $vesta-blue;
    border-color: $vesta-blue;
    color: $white;
    height: $button-height-small;
    &:hover {
      background-color: rgba($vesta-blue, 0.75);
      border-color: rgba($vesta-blue, 0.75);
    }
  }

  &.primary {
    background-color: $vesta-pink;
    border-color: $vesta-pink;
    color: white;

    span, a {
      color: white;
    }

    i {
      border-color: $white;
    }

    &:hover {
      background-color: rgba($vesta-pink, 0.75);
      border-color: rgba($vesta-pink, 0.75);
    }

    &:active {
      background-color: $vesta-pink;
      border-color: $vesta-pink;
    }

    &:disabled {
      background-color: $medium-grey;
      border-color:  $medium-grey;

      &:hover {
        background-color:  $medium-grey;
        border-color:  $medium-grey;
      }
    }
  }

  &.secondary {
    color: $color-primary;
    background-color: $vesta-blue;
    border-color: $vesta-blue;
    color: white;

    span, a {
      color: white;
    }

    i {
      border-color: $white;
    }

    &:hover {
      background-color: rgba($vesta-blue, 0.75);
      border-color: rgba($vesta-blue, 0.75);
    }

    &:disabled {
      background-color: rgba($color-button-background-disabled, 0.5);
      border-color: rgba($color-button-background-disabled, 0.5);
      &:hover {
        background-color: rgba($color-button-background-disabled, 0.25);
        border-color: rgba($color-button-background-disabled, 0.25);
      }
    }
  }

  &.underline {
    text-decoration: underline;
    background: none;
    padding: 0px;
    width: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  i {
    border-width: 0 4px 4px 0;
    padding: 4px;
  }

  &.none {
    appearance: none;
    border: none;
    box-shadow: none;
    width: fit-content;
    outline: none;
    text-align: center;
    margin: auto;
    min-height: initial;
    height: auto;
    line-height: normal;
    padding: 0;
    border-radius: initial;
    cursor: pointer;
    font-size: $font-size;
    width: auto;
    position: relative;
    background-color: transparent;
  }

}

.arrowButton {
  appearance: none;
  border: none;
  box-shadow: none;
  width: fit-content;
  outline: none;
  text-align: center;
  margin: auto;
  height: auto;
  background: none;
  padding: 0 $padding-tiny;

  &.primary {
    height: $button-height-small;
  }

  &.secondary {
    height: $button-height-small;
  }

  &:hover {
    text-decoration: underline;
  }

}

.linkButton {
  padding: $padding-link-button $padding-medium;
  text-decoration-line: none; 
}

.arrowLink {
  font-family: FuturaDemi;
  color: $vesta-blue;
  display: inherit;

  i {
      border-width: 0 4px 4px 0;
      padding: 4px;
      border-color: $vesta-blue;
  }
}

.arrowClass {
  &.pos-left {
    margin-right: 10px;
  }

  &.pos-right {
    margin-left: 10px;
  }

  transition: all 0.4s;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);

  &.up {
    // down
    transition: all 0.4s;

    vertical-align: middle;
    margin-top: 5px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &.down {
    // up
    transition: all 0.4s;

    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-bottom: 3px;
  }

  &.left {
    // right
    transition: all 0.4s;

    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &.right {
    // left
    transition: all 0.4s;

    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 

}