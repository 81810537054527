@import "scss/base.scss";
@import "pages/Statistics/styles.module.scss";

.attMessageContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  margin: $margin-largest * 4 0;
}

.statsGroup {
  padding: $general-page-top-margin $padding-large;
  flex: 1;
  overflow-y: scroll;

  &.isDashboard {
    background-color: $light-grey;
  }

  .filter {
    flex: 1;
  }

  .checkboxesFilter {
    margin: $margin-small 0;
    display: flex;
    justify-content: flex-end;

    .group {
      padding-left: 10px;
    }
  }

  .filtersHeader {
    margin: $margin-small 0;
    display: flex;
    align-items: center;

    .datePickersContainer {
      margin: $margin-small 0;
      display: flex;
      align-items: center;

      .noDateRange {
        margin-top: $margin-small;
        margin-right: $margin-small;
      }

      .rangeLabel {
        margin-right: $margin-small;
        font-family: 'FuturaHeavy';
        margin-top: $margin-small;
      }

      .datePickers {
        display: flex;
        align-self: flex-end;
  
        .dateSelector {
          margin-right: $margin-tiny;

          &:last-child {
              margin: 0;
          }

          .dateSelectorLabel {
              font-size: $font-size-microText;
          }
        }
      }
    }
  }

  .map {
    .lastPin {
      display: flex;
      flex-direction: row;
      width: 100%;

      .label {
        padding-right: 5px;
      }

      .date {
        flex: 1;
      }
    }

    .googleMap {
      width: 100%;
      height: 550px;
    }
  }

  .summaries {
    display:flex;
    flex-direction: row;
    width: 100%;

    .notPinnable {
      margin: 10px;

      .summary {
        border-radius: 4px;
        border: solid 1px $border-color;
        min-height: 300px;
        width: 100%;
        margin: 5px;
        padding: 20px;
        margin-top: 40px;
        margin-left: 35px;

        li {
          font-size: 18px;
        }
      }

      .title {
        font-size: 20px;
        font-weight: bolder;
        color: $color-text;
      }
    }

    .reportedLocations {
      width: 55%;
      margin: 10px;
      .reportSummary {
        
        border-radius: 4px;
        border: solid 1px $border-color;
        min-height: 300px;
        width: 100%;
        margin: 5px;
        margin-top: 40px;
        padding: 20px;

        .title {
          
          display: flex;
          flex-direction: row;
          align-items: center;
          

          .imageStyling {
            width: $icon-size-large;
            height: $icon-size-large;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            margin-right: $margin-small;
          }
          .anonymousIcon {
            background-size: contain;
            background-image: url('../../../assets/svgs/icon-location-blue.svg');
          }

          .nonAnonymousIcon {
            background-size: contain;
            background-image: url('../../../assets/svgs/icon-location-red.svg');
          }

          .description {
            flex: 1;
            font-size: 20px;
            font-weight: bolder;
            
          }
          .nonAnonymous {
            color: $vesta-dark-pink
          }
          .anonymous {
            color: $vesta-blue;
          }
        }

        .note {
          font-size: 18px;
          margin-top: 17px;
          .noteHighlight {
            color: $vesta-dark-pink;
            font-weight: bold;
          }
        }

        li {
          font-size: 18px;
        }
      }
    }
  }

  .statsHeader {
    display: flex;
    align-items: center;

    .title {
      margin-right: $margin-tiny;
      flex: 1;
    }
  }

  .downloadComponent {
    display: flex;
    text-decoration: underline;
    font-weight: 800;
    margin-left: 20px;

    .downloadComponentIcon {
      width: $icon-size;
      height: $icon-size;
      padding: 0 $padding-medium;
    }
  }
}
