@import "scss/base.scss";

.roleDescription {

  .roleTitle {
    display: flex;
    align-items: center;
    margin-bottom: $margin-small;

    .subheader {
      margin-right: $margin-tiniest;
    }
  }

  .permissionsContainer {
    .permissionContainer {
      padding-left: $padding-tiny;
      text-align: left;
    }
    
    $roleIconSize: $icon-size * 1.5;

    .greenCheckIcon, .crossIcon {
      width: $roleIconSize;
      height: $roleIconSize;
      min-width: $roleIconSize;
      min-height: $roleIconSize;
    }

    .permission {
      display: flex;
      align-items: center;
      margin-bottom: $margin-tiny;

      .permissionScope {
        color: $vesta-pink;
      }
    }

    .greyOpacity {
      opacity: 0.5;
      filter: grayscale(1);
    }
  }
}
