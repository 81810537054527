@import "scss/base.scss";

.organizationsTableContainer {
    padding: $padding-large;
    display: flex;
    flex-direction: column;

    .assignButton {
        width: auto;
        align-self: flex-end;
        margin: $margin-small 0;
    }
}