@import "scss/base.scss";

.basicInfo {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  .iconRow {
    min-width: $font-size-large;
    margin-right: $margin-tiniest;
  }

  .redBox {
    border-radius: $radius-tiny;
    border-style:solid;
    border-width: $border-size-large;
    border-color: $vesta-dark-pink;
    h5.label { 
      color: $vesta-dark-pink;
    }
  }

  .basicReportInfo, .fullBasicReportInfo {
    margin-right: $margin-small;
  }

  .basicReportInfoField {

    margin: $margin-small;

    .label {
        color: $vesta-blue;
    }

    div.label {
      display: flex;
      align-items: center;
      font-size: $font-size-small;
      margin-bottom: $margin-tiniest;

    }
  }  

  .withIcon {
    display: flex;
  }

  .explainer , .explainer p{
    margin: $margin-tiniest;
    font-size: $font-size-smallText;
  }
}



/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {

}
