@import "scss/base.scss";

.addOrEditOrganizationUsersForm {
  margin-top: $margin-large;

  .cancelOrSaveButtons {
    display: flex;
    margin: $margin-largest 0;

    > * {
      max-width: $button-max-width;
    }
  }

  .inputField {
    margin-bottom: $margin-small;
  }

  .orgUsersFormHalfFields {
    display: flex;

    .inputField {
      margin-right: $margin-large;
      flex: 1;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
