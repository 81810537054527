@import "scss/base.scss";

.usersTableContainer {
    padding: $padding-large;
    display: flex;
    flex-direction: column;

    .assignButton {
        width: auto;
        align-self: flex-end;
        margin: $margin-small 0;
    }

    .usersTable {
        margin: $margin-small 0;
    }

    .displayNone {
        display: none;
    }
}
