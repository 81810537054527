@import 'scss/base.scss';

.pageNavigation {
  position: absolute;
  bottom: 5%;
  left: 50%;
  background: white;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 5px 5px 0 #aeacac;
  border-radius: 4px;

  .prevButton {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .nextButton {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .pageNavigationButton {
    width: 44px;
    height: 44px;
    background: white;
    border: 0;
    font: inherit;
    font-size: 0.8em;
    border-radius: 4px;
  }
}
