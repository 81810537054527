@import "scss/base.scss";

.assignOrganizationUsersTableContainer {
  margin-top: $margin-tiny;

  .assignOrganizationUsersTable {
    border: solid $border-size $border-color;
    max-height: $table-max-height-small;
    overflow-y: scroll;
    margin-top: $margin-tiny;
  }

  .assignOrganizationUsersTableHeaders {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .smallText {
      color: $vesta-blue;
    }
  
    .assignedNone {
      visibility: hidden;
    }

    .organizationsSortSearch {
      display: flex;
      justify-content: flex-end;
  
      .searchInput {
        margin-left: $margin-small;
      }
    }
  }

  .noUsersMessage {
    margin: $margin-small 0;
    display: flex;
    justify-content: center;
  }
  
  .userRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $padding-tiniest $padding-tiny;
    border-bottom: solid $border-size $border-color;

    &:last-child {
      border-bottom: none;
    }

    .roleName {
      color: $off-grey;
    }
  }
}
