

.checkboxFilter {
  display: flex;
  flex-direction: row;
  padding: 3px 10px;

  .label {
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    .title {
      padding-left: 8px;
      margin: auto;
      text-align: center;
    }

    .circle {
      --circleRadius: 15px;
      width: var(--circleRadius);
      height: var(--circleRadius);
      margin: auto;
      border-radius: var(--circleRadius);
    }
    
    .orange {
      background-color: orange;
    }
    .green {
      background-color: greenyellow;
    }
    .blue {
      background-color: navy;
    }
    .purple {
      background-color: purple;
    }
    .black {
      background-color: black;
    }
    .none {
      background-color: transparent;
      border: 1px solid black;
    }
  }
  .checkbox {
    display: flex;
    flex: 1;
    justify-content: end;
  }
}
