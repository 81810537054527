@import 'scss/base.scss';


.leftNav {
  border-right: solid 1px $border-color;
  background-color: $vesta-blue;
  min-width: $left-nav-width;
  max-width: $left-nav-width;
  color: white;

  * {
    color: $white;
  }

  .userInfoSection {
    border-bottom: solid 1px $white;
    color: $white;
    padding: $general-page-top-margin $padding-large;

    .whiteVestaLogo {
      width: 50px;
      height: 50px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../../assets/images/pngs/VESTA_logo_white.png');
      margin-bottom: $margin-large;
    }

    .logoutButton {
      margin-top: $margin-large;
    }

    .userLink {
      display: flex;
      align-items: center;
      
      .userButton {
        margin: 0;
        margin-right: $margin-tiniest;
        text-align: left;
        margin-bottom: $margin-tiny;

        &:hover {
          text-decoration: underline;
        }

        &.active {
          text-decoration: underline;
        }
      }

      .doubleRightArrow {
        .arrowIcon {
          width: $icon-size-small;
          height: $icon-size-small;
          background-position: inherit;
        }
      }
    }
  }

  .navPaths {
    padding: $padding-large;
    padding-right: $padding-tiny;

    .navItem {
      display: flex;
      align-items: center;
      margin-bottom: $margin-small;

      .subheader {
        text-align: left;
      }
  
      .imageStyling {
        width: $icon-size-large;
        height: $icon-size-large;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        margin-right: $margin-small;
      }
    
      .dashboard {
        background-size: contain;
        background-image: url('../../assets/svgs/icon-home.svg');
      }
    
      .reports {
        background-size: contain;
        background-image: url('../../assets/svgs/icon-report.svg');
      }
    
      .userRoles {
        background-size: contain;
        background-image: url('../../assets/svgs/icon-roles.svg');
      }

      .manageDepartments {
        background-size: contain;
        background-image: url('../../assets/svgs/icon-manageDepartments.svg');
        width: $icon-size-large * 1.75;
        margin-left: -$margin-tiniest * 2;
        margin-right: $margin-small - $margin-tiniest * 2;
      }

      .manageOrganizations {
        background-size: contain;
        background-image: url('../../assets/svgs/icon-personOrg.svg');
        width: $icon-size-large * 1.75;
        height: $icon-size-large * 1.45;
        margin-left: -$margin-tiniest * 2;
        margin-right: $margin-small - $margin-tiniest * 2;
      }
    
      .statistics {
        background-size: contain;
        background-image: url('../../assets/svgs/icon-stats.svg');
      }
      
      .locationStatistics {
        background-size: contain;
        background-image: url('../../assets/svgs/icon-location-white.svg');
      }

      .feedback {
        background-size: contain;
        background-image: url('../../assets/svgs/icon-nav-feedback.svg');
        width: $icon-size-large * 1.75;
        margin-left: -$margin-tiniest * 2;
        margin-right: $margin-small - $margin-tiniest * 2;
      }

      .doubleRightArrow {
        display: none;
        margin-left: $margin-tiniest;
    
        .arrowIcon {
          width: $icon-size-small;
          height: $icon-size-small;
          background-position: inherit;
        }
      }

      &:hover {
        text-decoration: underline;
        color: $white;
        cursor: pointer;
      }

      &.active {
        .subheader {
          text-decoration: underline;
          color: $white;
        }
  
        .doubleRightArrow {
          display: block;
        }
      }
    }
  }

  .version{
    color: $white;
    margin-left:$margin-large;
  }
}
