@import "scss/base.scss";

.statCard {
  display: flex;
  align-items: center;
  padding: $padding-tiny $padding-small;
  border-radius: 4px;
  border: solid 1px $border-color;
  background-color: $white;

  h1 {
    padding-right: $padding-tiny;
  }

  .label {
    margin-left: $margin-tiny;
  }

  .externalLinkIcon {
    width: $icon-size;
    height: $icon-size;
    padding: 0 $padding-medium;
    filter: brightness(0) invert(1);
  }
}
