@import "scss/base.scss";
@import "scss/icons.scss";

$dropdown-width: 200px;

.dropdown {
  display: inline-flex;
  flex-direction: column;
  width: $full;
  position: relative;
  width: auto;

  .dropdownContainer {
    label {
      margin-right: $margin-tiny;
    }

    select {
      background: $white;
      padding: 0 $margin-tiny;
      -webkit-appearance: none;
      outline: none;
      border: 1px solid $border-color;
      flex: 1;
      width: 100%;
      color: $dark-grey;
      resize: none;
      height: $input-min-height-tiny;
      min-height: $input-min-height-tiny;
      width: $dropdown-width;
      cursor: pointer;
    }

    &::after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      right: $margin-tiny;
      top: 0;
      bottom: 0;
      margin: auto;
      pointer-events: none;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url('../../../assets/svgs/icon-dropdown-arrow.svg');
    }
  }
}
