@import "scss/base.scss";


.confirmationModal {
  max-width: $one-fourth;
  min-width:  300px;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,0.23), 0 3px 6px 0 rgba(0,0,0,0.16);
  
  .confirmationModalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    .greenCheckIcon {
      width: $icon-size * 3;
      height: $icon-size * 3;
      margin: auto;
    }
  
    .confirmationMessage {
      margin: $margin-large 0;
    }

    .confirmationButtonContainer {
      width: $half;

      a {
        display: inline-block;
      }

      .confirmationButton {
        width: 100%;
      }
    }
  }
}


