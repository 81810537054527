@import "scss/base.scss";

.attachmentDisplayContainer {
    position: relative;
    //max-width: 530px;
    width: $full;
    margin: auto;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: $dark-grey;
    }

    &.active {
        &::after {
            display: none;
        }
    }
}