@import "scss/base.scss";
@import "scss/icons.scss";

.addOrEditUserPage {
  padding: $general-page-top-margin;
}

.addOrEditUserPageContent {
  display: flex;

  .middle {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: $padding-largest;
  }

  .right {
    padding-left: $padding-medium;
    border-left: solid 1px $border-color;
    width: $half;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .userSuccessfullyAddedOrEdited {
    margin: $margin-large 0;
  }
}
