@import "scss/base.scss";

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {

  .reportData {
    display: flex;
    height: 100vh;

    h3 {
      color: $vesta-blue;
    }

    .middle {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-height: 100vh;
      overflow-y: scroll;
      margin-bottom: $margin-largest;
    }

    .right {
      border-left: solid 1px $border-color;
      width: 40%;
      position: relative;

      .noTimelineMessage {
        position: absolute;
        display: block;
        top: 50%;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
        text-align: center;
        max-width: 50%;
        font-style: italic;
      }
    }
  }
}
