@import "scss/base.scss";

.reportTimeline {
  .header {
    margin: $margin-large $margin-large $margin-tiny $margin-large;
  }

  .reportTimeStepsContainer {
    overflow-y: scroll;
    position: relative;
    padding-bottom: $padding-largest;
    border-top: solid 1px $border-color;
    border-bottom: solid 1px $border-color;
    background-color: $light-grey;
    max-height: 50vh;
    min-height: 50vh;
  }

  .noteContent {
    margin-bottom: $margin-tiny;
  }

  .microText {
    color: $vesta-blue;
  }
}


/* Hide scrollbar for Chrome, Safari and Opera */
.reportTimeStepsContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.reportTimeStepsContainer {
  -ms-overflow-style: none;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {
}
