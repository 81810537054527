@import "scss/base.scss";


.adminNotes {
  padding: $padding-small;

  button {
    margin-top: $margin-small;
    width: $half;
    float: right;
  }
}
