@import "scss/base.scss";

.reportsPageContent {
  padding: $general-page-top-margin 0;
  min-width: $content-min-width;

  .reportsFilterSortSearch {
    display: flex;
    justify-content: flex-end;
    padding: 0 $padding-large;

    .searchInput {
      margin-left: $margin-small;
    }
  }

  .reports {
    .report {
      display: flex;
      flex: 1;
      border: solid $border-size $border-color;

      .reportStatus {
        padding: 0 $padding-large;
      }

      .reportStatus::before {
        content: "•";
        font-size: $font-size;
      }

      .externalLinkIcon, .helmIcon {
        width: $icon-size-large;
        height: $icon-size-large;
        padding: 0 $padding-medium;
      }
    }

  }
}
