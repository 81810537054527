@import 'scss/base.scss';

.pdfContainer {
  max-width: $full;
  border: solid $border-size*2 $border-color;
  margin: $margin-small 0;
}
.innerPdfContainer {
  max-width: $full;
}

.pdfContainer:hover {
  .pdfPageNavigation {
    opacity: 1;
  }
}
