@import "scss/base.scss";

$nameWidth: 70%;
$typeWidth: 20%;
$editWidth: 10%;

.organizationsTable {
  display: flex;
  flex-direction: column;

  .noOrgMessage {
    margin: $margin-small 0;
    display: flex;
    justify-content: center;
  }

  .organizationsTableContainer {
    margin-top: $margin-large;
  }

  .organizationsFilterSortSearch {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: $margin-small;
  }

  .addButtonContainer {
    align-items: center;
    width: auto;
    justify-self: flex-start;
    margin: auto;
    margin-left: 0;
  }

  .addButton::before {
    content: "+";
    color: $white;
    font-size: $font-size-large;
    font-weight: 600;
    vertical-align: middle;
  }

  .addButton {
    .addButtonText {
      padding: 0 $padding-small;
    }
  }

  .organizationsTableDropdown {
    margin-right: $margin-tiny;
  }

  .organizationsRows {
    max-height: $table-max-height-large;
    overflow-y: scroll;
    border: solid 1px $border-color;
  }

  .organizationsTableRow {
    display: flex;
    align-items: center;
    padding: $padding-tiny;

    &.rowHeader {
      padding: $padding-tiniest;
      margin-right: $scrollbar-width;
      p {
        font-size: $font-size-microText;
      }
    }
  }

  .rowBorder {
    border-bottom: solid $border-size $border-color;

    &:last-child {
      border-bottom: none;
    }
  }

  .name {
    width: $nameWidth;
  }
  .type {
    width: $typeWidth;
  }
  .edit {
    width: $editWidth;
    text-align: center;
    align-self: flex-end;

    &.icon {
      height: $icon-size-large;
    }
  }
}
