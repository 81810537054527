@import "scss/base.scss";

.sectionContainer {
  .sectionHeader {
    display: flex;
    align-items: center;
    border-bottom: solid 1px $border-color;
    padding: $padding-small;
    position: relative;
  
    .arrowIcon {
      width: $icon-size;
      height: $icon-size;
      margin: 0;
      position: absolute;
      right: $margin-large;

      &.up {
        transform: rotate(-90deg);
      }
    }
  }

  .sectionContent {
    padding: $padding-small;

    &.down {
      display: none;
    }
  }
}