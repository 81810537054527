// Large base
:root {
    font-size: 16px;
}

// Font sizes
$font-size-h2: 1.5rem;
$font-size-h3: 1.325rem;
$font-size-h4: 1.25rem;
$font-size-h5: 1.125rem;
$font-size-h6: 1.1rem;
$font-size: 1rem;
$font-size-smallText: 0.8rem;
$font-size-microText: 0.7rem;

$padding-tiniest: 0.2rem;
$padding-tiny: 0.625rem;
$padding-small: 1.25rem;
$padding-medium: 1rem;
$padding-large: 1.5rem;
$padding-largest: 2rem;

$radius-tiny: 0.5rem;
$radius-small: 1.25rem;
$radius: 1.5rem;
$radius-large: 3rem;

$margin-tiniest: 0.2rem;
$margin-tiny: 0.625rem;
$margin-small: 1.25rem;
$margin-medium: 1rem;
$margin-large: 1.5rem;
$margin-largest: 2rem;

$font-size-tiniest: 0.45rem;
$font-size-tiny: 0.6rem;
$font-size-small: 0.75rem;
$font-size-medium: 0.85rem;
$font-size: 1rem;
$font-size-large: 1.25rem;
$font-size-largest: 2rem;

$border-size: 1px;
$border-size-large: 3px;

$icon-size-tiny: 0.5rem;
$icon-size-small: 0.75rem;
$icon-size: 1rem;
$icon-size-large: 1.25rem;
$icon-size-larger: 1.5rem;
$icon-size-sensory: 4rem;

$button-size-small: 1.75rem;
$button-size-medium: 2rem;
$button-size-large: 2.5rem;

$line-height: 1rem;

// Media Query Sizing
$tablet-min-breakpoint: 768px;
$desktop-min-breakpoint: 1024px;
$tablet-max-breakpoint: calc(#{$desktop-min-breakpoint} - 1px);

// Percentage sizing
$full: 100%;
$half: 50%;
$one-third: 33.33%;
$two-third: 66.66%;
$one-fourth: 25%;
$three-fourth: 75%;

$modal-max-width: $two-third;
$modal-max-width-large: $half;

$report-form-width: calc(50% + 80px); // 7 columns of a 12 column system
$padding-link-button: 0.45rem;

$border-vesta: solid $border-size $border-color;

//Pixel sizing
$logo-height: 55px;
$logo-height-small: 35px;
$report-page-max-width: 812px;
$general-page-max-width: 1240px;
$radius-rounded-corners: 8px;
$radius-large-rounded-corners: 10px;
$input-min-height-tiny: 35px;
$input-min-height-small: 40px;
$input-min-height: 45px;
$button-height-small: 40px;
$button-height: 45px;
$icon-textarea-max-width: 90px;
$header-max-height: 180px;
$page-height-max: 768px;
$path-image-height: 140px;
$sm-group-icon-width: 225px;
$path-image-height-large: 180px;
$table-row-min-height: 55px;
$general-page-top-margin: $padding-large * 2;
$table-max-height-small: 400px;
$table-max-height-large: 70vh;
$left-nav-width: 250px;
$content-min-width: 860px;
$button-max-width: 160px;
$scrollbar-width: 10px;
