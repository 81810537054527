@import "scss/base.scss";


.yesNoModal {
  max-width: $one-third;
  min-width:  $one-third;
  
  .yesNoModalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    .yesNoButtons {
        display: flex;
        width: $full;

        button {
            margin: $margin-tiny;
        }
    }
  
    .yesNoMessage {
      margin: $margin-large 0;
      text-align: center;
    }
  }
}


