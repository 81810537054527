@import "scss/base.scss";


.label {
 margin-bottom: $margin-tiniest;
} 

.required, .alert, .errorMessage {
  color: $color-form-error;
  text-align: left;
}

.errorField {
  border-color: $color-form-error;
  color: $color-form-error;
}

.textFieldContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  &.inlineTextInput {
    flex-direction: row;
    align-items: center;

    input {
      margin-left: $margin-tiny;
    }
  }
}

.icon {
  position: absolute;
  right: $margin-tiny;
}

.textInput {
  background-color: $white;
  -webkit-appearance: none;
  outline: none;
  border: solid $border-size $border-color;
  border-radius: $radius-rounded-corners;
  height: $input-min-height-small;
  flex: 1;
  width: $full;
  padding: $padding-tiniest;
  padding-left: $padding-tiny;
  padding-right: calc(#{$padding-largest} + #{$padding-small});
  height: $input-min-height;
  min-height: $input-min-height;
  
  &.inlineTextInput {
    display: inline-block;
    width: 150px;
  }

  &.phoneNumber3Digits {
    display: inline-block;
    width: 80px;
  }

  &.phoneNumber4Digits {
    display: inline-block;
    width: 130px;
  }

  &::placeholder {
      font-style: italic;
      color: $medium-grey;
  }
}

.textarea {
  background: $white;
  padding: $margin-tiny;
  -webkit-appearance: none;
  outline: none;
  border: 1px solid $border-color;
  border-radius: 8px;
  min-height: 200px;
  flex: 1;
  width: 100%;
  color: $dark-grey;
  resize: none;
  margin-top: 5px;

  &.hasIcon {
    padding-right: calc(#{$icon-textarea-max-width} + #{$margin-large} + #{$margin-large});
  }
  
  &::placeholder {
      font-style: italic;
      color: $medium-grey;
  }

}

.textAreaContainer {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: $full;
    width: $icon-textarea-max-width;
    background-position: center;
    top: 0;
    right: $margin-large;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.errorField {
  border-color: $color-form-error;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) {

}
