/*
Try to utilize variables as much as possible.  It's one of the best things about a CSS preprocessor like Sass and it makes updates much easier
*/

//theme defaults
$color-primary: #787878;
$color-secondary: #b1df95;
$color-alert: #ff0000;
$color-success: #b1df95;
$color-form-error: #D90038;

//  colour background
$color-background: #ffffff;
$color-background-secondary: #551d00;
$color-highlight: #b1df95;
$color-box-shadow: #888888;
$color-background-footer: #b1df95;

// colour text
$color-text: #787878;
$color-text-secondary: #ffffff;
$color-text-link: #ffc7aa;
$color-text-disabled: #787878;

$color-border: #a3a3a3;

// buttons
$color-button-background: $color-background-secondary;
$color-button-text: $color-secondary;

$color-button-background-disabled: #c7c7c7;

// borders:
$color-border: $color-primary;

// modals
$color-modal-background: #d48e6a;
$color-modal-text: #ffffff;

// header
$color-header-border: #b1df95;
$color-menu-text: #551d00;
$color-menu-background: #ffffff;

//flashtext
$color-flash-from: #d48e6a;


//Brand colours
$gradient-blue: #6A788E;
$gradient-pink: #B99FAB;
$light-grey: #F5F5F5;
$off-grey: #9D9D9D;
$medium-grey: #CECECE;
$dark-grey: #6F6F6F;
$vesta-pink: #B57E91;
$vesta-blue: #6A788E;
$vesta-brown: #60494A;
$vesta-dark-pink: #8B304B;
$border-color: #D8D8D8;
$white: #FFFFFF;
$vesta-green: #7BAD54;

$dark-error-red: #D90038;

//Auxiliary colours
$vesta-purple: #c570e0;

//Report Statuses
$color-reportStatus-needsFollowUp: #D90038;


// Shadows
$box-shadow-stats: 0 3px 8px 0 rgba(0,0,0,0.23), 0 3px 6px 0 rgba(0,0,0,0.16);


