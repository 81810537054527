@import 'scss/base.scss';

.feedback {
    padding: $general-page-top-margin $padding-large;
    overflow-y: scroll;

    .feedbackContent {
        display: flex;
        margin-top: $margin-large;
        
    }
}

$experienceWidth: 15%;
$helpfulnessWidth: 10%;
$recommendedWidth: 10%;
$howtoImproveWidth: 65%;

.feedbackTable {
  display: flex;
  flex-direction: column;
  width: $full;

  .usersTableContainer {
    margin-top: $margin-large;
  }

  .usersFilterSortSearch {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: $margin-small;
  }

  .addButtonContainer {
    align-items: center;
    width: auto;
    justify-self: flex-start;
    margin: auto;
    margin-left: 0;
  }

  .addButton::before {
    content: "+";
    color: $white;
    font-size: $font-size-large;
    font-weight: 600;
    vertical-align: middle;
  }

  .addButton {
    .addButtonText {
      padding: 0 $padding-small;
    }
  }

  .userTableDropdown {
    margin-right: $margin-tiny;
  }

  .noUsersMessage {
    margin: $margin-small 0;
    display: flex;
    justify-content: center;
  }

  .userRows {
    max-height: $table-max-height-large;
    overflow-y: scroll;
    border: solid 1px $border-color;
  }

  .usersTableRow {
    display: flex;
    align-items: center;
    padding: $padding-tiny;

    &.rowHeader {
      padding: $padding-tiniest;
      margin-right: $scrollbar-width;
      p {
        font-size: $font-size-microText;
      }
    }
  }

  .rowBorder {
    border-bottom: solid $border-size $border-color;

    &:last-child {
      border-bottom: none;
    }
  }

  .experience {
    width: $experienceWidth;
  }

  .helpfulness, .recommended {
    width: $recommendedWidth;
  }

  .howtoImprove {
    width: $howtoImproveWidth;
    height: $icon-size-large;
  }
}