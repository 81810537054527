@import "scss/base.scss";
@import "pages/Statistics/styles.module.scss";

$trendGraphHeight: 350px;
$duoBarGraph: 120px;
$trioBarGraph: 180px;
$quadBarGraph: 220px;
$consentOrOffenderGraph: 350px;

.attMessageContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  margin: $margin-largest * 4 0;
}

.statsGroup {
    padding: $general-page-top-margin $padding-large;
    flex: 1;
    overflow-y: scroll;

    &.isDashboard {
      background-color: $light-grey;
    }

    .datePickerHeader {
      margin: $margin-small 0;
      display: flex;
      align-items: center;

      .rangeLabel {
        margin-right: $margin-small;
        font-family: 'FuturaHeavy';
        margin-top: $margin-small;
      }

      .datePickers {
        display: flex;
        align-self: flex-end;
  
        .dateSelector {
            margin-right: $margin-tiny;
  
            &:last-child {
                margin: 0;
            }
  
            .dateSelectorLabel {
                font-size: $font-size-microText;
            }
        }
      }
    }



  .statsHeader {
    display: flex;
    align-items: center;

    .title {
      margin-right: $margin-tiny;
    }

    .subtitle {
      flex: 1;
      font-style: italic;
    }

    .viewOtherJurisdictionsLink {
      display: flex;
      margin-left: $margin-large;
      text-decoration: underline;
      font-style: italic;

      .externalLinkIcon {
        width: $icon-size-small;
        height: $icon-size-small;
        padding: 0 $padding-medium;
      }
    }
  }

  .downloadComponent {
    display: flex;
    text-decoration: underline;
    font-weight: 800;

    .downloadComponentIcon {
      width: $icon-size;
      height: $icon-size;
      padding: 0 $padding-medium;
    }
  }
  
  .nonGraphs {
    display: flex;
    margin: $margin-small 0;
    
    .totalReports {
      background-color: $vesta-pink;
      margin-right: $margin-small;

      h1, span {
        color: $white;
      }
    }
  
    .savedButNotSubmittedPercentage {
      margin-right: $margin-small;

      h1 {
        color: $gradient-blue;
      }
    }
  
    .activeCasesPercentage {
      margin-right: $margin-small;

      h1 {
        color: $vesta-dark-pink;
      }
    }
  }

  .statGraphs {
    display: flex;
  }

  .trendGraph {
    background-color: $white;
    height: $trendGraphHeight;
    margin-right: $margin-small;
    width: $half;
    border-radius: 4px;
    border: solid 1px $border-color;
    background-color: $white;
    margin-bottom: $margin-small;
    padding: $padding-small;
    padding-left: 0;
    flex-direction: column;

    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-style: italic;
    }
  }

  .secondaryStats {
    display: flex;

    .horizontalBarGraphs:first-of-type {
      margin-right: $margin-small;
    }
  }

  .horizontalBarGraphs {
    display: flex;
    flex-direction: column;
    width: $half;
    min-height: $trioBarGraph;

    .counsellorPoliceConsent, .knownNamedOffenders, .policeStatusesData, .supportCenterStatusesData {
      width: $full;
      height: $trioBarGraph;
      border-radius: 4px;
      border: solid 1px $border-color;
      background-color: $white;
      margin-bottom: $margin-small;
      padding: $padding-small 0;
    }

    .counsellorPoliceConsent, .knownNamedOffenders {
      height: $consentOrOffenderGraph;
    }

    .policeStatusesData, .supportCenterStatusesData {
      height: $trendGraphHeight;
    }
  }
}
