@import "scss/base.scss";
@import "scss/icons.scss";

.addOrEditDepartmentUsersPage {
  padding: $general-page-top-margin $padding-large;
  min-height: 100vh;

  .addOrEditDepartmentUsersContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: $padding-largest;
  }

  .departmentSuccessfullyAddedOrEdited {
    margin: $margin-large 0;
  }
}
