@import "scss/base.scss";

.optionsBubble {
  .options {
    flex-wrap: nowrap;
    display: flex;
    margin: $margin-tiniest 0;
  }
}

.option {
  display: inline-block;

  &:first-of-type {
    .radio + label {
      border-top-left-radius: $radius-large;
      border-bottom-left-radius: $radius-large;
    }
  }

 &:last-of-type {
    .radio + label {
      border-top-right-radius: $radius-large;
      border-bottom-right-radius: $radius-large;
    }
  }

  .optionsBubbleLabel {
    margin-right: $margin-tiniest;
    display: inline-block;
  }

  .radio:checked,
  .radio:not(:checked) {
    position: absolute;
    opacity: 0;
  }
  
  .radio:checked + label,
  .radio:not(:checked) + label
  {
    position: relative;
    padding: 0px $padding-tiny;
    cursor: pointer;
    display: inline-block;
    font-size: $font-size-small;
    white-space: nowrap;
    margin: 0px;
  }

  .radio + label {
    height: $input-min-height-small;
    border: solid $border-size $border-color;
    line-height: $input-min-height-small;
    background-color: $white;
  }

  .radio:checked + label {
    background-color: $vesta-pink;
    border-color: $vesta-pink;
    color: $white;
  }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
  .options {
    flex: 1;
    flex-wrap: nowrap;
    display: flex;
    margin-top: 0px;
    margin-left: $margin-tiny;
  }

  .option {
    .optionsBubbleLabel {
      margin-right: $margin-tiniest;
      display: inline-block;
    }
    .radio:checked + label,
    .radio:not(:checked) + label
    {
      padding: 0px $padding-tiny;
      cursor: pointer;
      display: inline-block;
      font-size: $font-size;
      white-space: nowrap;
    }
    .radio + label {
      height: $input-min-height;
      line-height: $input-min-height;
    }
  }
}
