@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideOutLeft {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes slideInRight {
    from {
        transform: translate3d(100%, 0, 0);
        visibility: visible;
    }

    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeInFromTop {
    from {
        opacity:0;
        -webkit-transform: translate3d(0, -50px, 0);
        transform: translate3d(0, -50px, 0);
    }
    to {
        opacity:1;
        -webkit-transform: translate3d(0, 0px, 0);
        transform: translate3d(0, 0px, 0);
    }
}

@keyframes fadeInFromBottom {
    from {
        opacity:0;
        -webkit-transform: translate3d(0, 50px, 0);
        transform: translate3d(0, 50px, 0);
    }
    to {
        opacity:1;
        -webkit-transform: translate3d(0, 0px, 0);
        transform: translate3d(0, 0px, 0);
    }
}

@keyframes fadeOutToTop {
    from {
        opacity:1;
        -webkit-transform: translate3d(0, 0px, 0);
        transform: translate3d(0, 0px, 0);

    }
    to {
        opacity:0;
        -webkit-transform: translate3d(0, -50px, 0);
        transform: translate3d(0, -50px, 0);
    }
}

@mixin fadeInFromTop {
    animation: fadeInFromTop 1.2s forwards;
}

@mixin fadeOutToTop {
    animation: fadeOutToTop 0.8s forwards;
}

@mixin fadeInFromBottom {
    animation: fadeInFromBottom 1.2s forwards;
}

// Individual defined animations
.reportPageAnim {
    &.page-enter{
        animation: slideInRight 0.8s forwards;
    }
}

.reportPageAnim {
    &.page-exit {
        animation: slideOutRight 0.8s forwards;
    }
}

.homePageAnim {
    &.page-enter {
        animation: slideInLeft 0.8s forwards;
    }
}

.homePageAnim {
    &.page-exit {
        animation: slideOutLeft 0.8s forwards;
    }
}

.fadeOutTop-exit {
    @include fadeOutToTop;
}

.disappear-exit {
    opacity: 0;
}
