@import "scss/base.scss";

.setUserRoleField {

  .rolesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: $border-vesta;
    border-radius: 8px;
    margin-top: $margin-tiny;
    max-height: 230px;
    overflow-y: scroll;
  
    .role {
      display: flex;
      justify-content: space-between;
      width: 95%;
      padding: $padding-tiny;
      align-items: center;
  
      .greenCheckIcon {
        width: $icon-size * 1.5;
        height: $icon-size * 1.5;
        display: none;
      }

      .visible {
        display: unset;
      }
    }
  
    .roleBorder:not(:last-child) {
      border-bottom: $border-vesta;
    }

    &.errorField {
      border-color: $color-form-error;
      font-size: $font-size-small;
    }
  }

  .alert {
    color: $color-form-error;
  }
}
