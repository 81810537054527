@import "./base/colors.scss";
@import "./base/sizes.scss";
@import "./base/helpers.scss";
@import "./base/anim.scss";
@import "./base/media-queries.scss";
@import "./base/components.scss";
@import "./icons.scss";

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.row {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.hide {
  display: none;
}

.greyed-out {
  color: $color-text-disabled !important;
}

p, div, a, span, link, button, input, textarea, select {
  font-family: 'FuturaBook';
  font-size: $font-size;
  margin: 0;
  color: $dark-grey;
  text-decoration: none;
}

a {
  text-decoration: none;
  &:visited {
    color: inherit;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: 'FuturaHeavy';

  &.subheader {
    font-family: 'FuturaBook';
    font-weight: 100;
  }

  span {
    font-family: FuturaHeavy;
    margin: 0;
  }
}

.required, .alert, .errorMessage {
  color: $color-form-error;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

h4 {
  font-size: $font-size-h4;
}

h5 {
  font-size: $font-size-h5;
}

h6 {
  font-size: $font-size-h6;
}

p {
  &.smallText {
    font-size: $font-size-smallText;
  }

  &.microText {
    font-size: $font-size-microText;
  }
}

  /* width */
  ::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }


// Library overwrites

  .react-datepicker-popper {
    z-index: 999;
  }
